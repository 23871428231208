.Input {
  width: 100%;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /*border: cadetblue solid 1px;*/
}

.InputElement {
  outline: none;
  border: 1px solid var(--lightGray);
  background-color: var(--mainWhite);
  font: inherit;
  padding: 6px 10px;
  width: 60%;
  box-sizing: border-box;
}

.InputElement:focus {
  outline: none;
}
