.modal {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  position: fixed;
  background-color: rgba(0,0,0,.7);
  padding-top: 2%;
}

.modal-inner {
  width: 95vw;
  max-width: 600px;
  max-height: 700px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  margin: 0 auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.modal-content {
  flex: 1;
  overflow: auto;
  padding: 10px;
}
