.DeleteModal {
  color: var(--mainDark);
  padding: 15px;
  border: 1px solid darkgray;
  box-sizing: border-box;
}

@media (max-width: 700px) {
  .DeleteModal {
    margin: 10px;
  }
}
