.AuthMessageTitle {
  font-weight: bold;
  font-size: larger;
  text-decoration: none;
  color: #484848;
}

.AuthMessageSubtitle {
  font-size: larger;
  text-decoration: none;
  color: #484848;
  /*border: cadetblue solid 1px;*/
}
