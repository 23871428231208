.ListModal {
  font-weight: bold;
  font-size: larger;
  /*border: cadetblue solid 1px;*/

  color: #000000;
  margin: 10px auto 10px auto;
  padding: 15px;
  text-align: center;
  border: 1px solid darkgray;
  box-sizing: border-box;
}

@media (max-width: 700px) {
  .ListModal {
    margin: 10px 10px 10px 10px;
  }
}
