.ListItem {
  display: inline-block;
  padding: 10px 0 10px 10px;
  width: 80%;
  min-height: 20px;
  /*border: cadetblue solid 1px;*/
}

.ListItemDone {
  text-decoration: line-through;
  color: var(--mainGray);
  /*    border: cadetblue solid 1px;*/
}
