:root {
  --mainBlue: #2a2a72;
  --lightBlue: #009ffd;
  --mainWhite: #ffffff;
  --mainDark: #232528;
  --mainBlack: #000000;
  --mainYellow: #ffa400;
  --lightGray: #efefef;
  --thinGray: #cccccc;
  --mainGray: #4c4c4c;
  --error: #b31b12;
}
