.HomeTitle {
  font-weight: bold;
  font-size: larger;
  text-decoration: none;
  color: var(--mainGray);
  /*border: cadetblue solid 1px;*/
}

.HomeSubtitle {
  font-size: larger;
  text-decoration: none;
  color: var(--mainGray);
  /*border: cadetblue solid 1px;*/
}

.HomeTitle:hover,
.HomeSubtitle:hover {
  color: var(--mainDark);
}
