.ListEditor a {
  text-decoration: none;
  box-sizing: border-box;
  color: var(--mainGray);
}

.ListEditor a:hover,
.ListEditor a.active {
  color: var(--mainDark);
}

.Error {
  font-weight: bold;
  color: var(--error);
}
